import React, {useEffect, useState} from "react";
import SimpleImageSlider from "react-simple-image-slider";
import useResizeObserver from "use-resize-observer";

const backgroundImageURL = "https://firebasestorage.googleapis.com/v0/b/frontdesk-screen.appspot.com/o/bg_new.png?alt=media&token=cdbbce6c-7a70-4b4a-8da5-4244823218dc";
const bubbleImage = process.env.REACT_APP_BUBBLE_IMG_URL;

function prepareEvents(callback) {
    const eventsAPI = process.env.REACT_APP_SOCAL_EVENTS_API_URL;
    let eventList = [];

    fetch(eventsAPI, {
        method: 'GET',
    })
        .then(response => response.json())
        .then((eventData) => {
            eventData.sort((firstDate, secondDate) => secondDate.startDate - firstDate.startDate).slice(0, 16).forEach(customEvent => {
                eventList.push({
                    "name": customEvent.eventName,
                    "startDate": new Date(customEvent.startDate).toLocaleString(),
                    "endDate" : new Date(customEvent.endDate).toLocaleString(),
                    "imageUrl": customEvent.image
                });
            });
            callback(eventList);
        })
        .catch(error => console.error(error));
}

export const IFrame = () => {
    const { ref, width = 1, height = 1 } = useResizeObserver();
    const imageURLs =
        [
            { url: "https://images1.loopnet.com/i2/XxZ040vZAb6z3rft6s8hn0okv0ThrZJVJnWQO34zhKs/116/1522-Brookhollow-Dr-Santa-Ana-CA-Building-Photo-1-LargeHighDefinition.jpg" },
            { url: "https://images1.loopnet.com/i2/ocu7QPGfgxjFdvaX0DD8RBibyNUToj7Iz9uWC0bE4bg/116/1522-Brookhollow-Dr-Santa-Ana-CA-Building-Photo-5-LargeHighDefinition.jpg"},
            { url: "https://images1.loopnet.com/i2/IvY6W68qFWuLkZiHGokCvMgNysALTTaLNBCZ4Kzh6So/116/1522-Brookhollow-Dr-Santa-Ana-CA-Primary-Photo-2-LargeHighDefinition.jpg" },
            { url: "https://images1.loopnet.com/i2/szJOToc3AiQgb0hZCnUjibWFenE7_ATVcgnBbqU3cg0/116/1522-Brookhollow-Dr-Santa-Ana-CA-Building-Photo-6-LargeHighDefinition.jpg" }
        ];
    // const [eventsList, setEventsList] = useState([]);

    // useEffect(() => {
    //     let res = prepareEvents(setEventsList);
    // }, []);

    return <div style={{
        backgroundImage: `url("${backgroundImageURL}")`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: '100%',
        width: '100%'

    }}>
        <div ref={ref} className="image-box" style={{
            paddingTop: '10vh',
            paddingLeft: '1vh',
            paddingRight: '10vh',
            width: "80vw",
            height: "82vh"
        }}
            >
            <SimpleImageSlider
                width={width}
                height={height}
                images={imageURLs}
                showBullets={true}
                showNavs={true}
                loop={true}
                autoPlay={true}
                autoPlayDelay={10}
                slideDuration={1.5}
                useGPURender={true}
            />
        </div>

        {/*{!!eventsList.length && <div style={{*/}
        {/*    top: 240,*/}
        {/*    left: 100,*/}
        {/*    width: 2800,*/}
        {/*    height: 1700,*/}
        {/*    position: "relative",*/}
        {/*    display: "grid",*/}
        {/*    ridTemplateColumns: "repeat(4, 1fr)",*/}
        {/*    gap: "10px",*/}
        {/*    gridAutoRows: "minmax(441px, 541px)"*/}
        {/*}}>*/}
        {/*    {eventsList.map((event, index) => <Bubble key={index} {...event} index={index}/>)}*/}
        {/*</div>}*/}

        {/*{!eventsList?.length &&*/}
        {/*    <div style={{*/}
        {/*        alignItems: 'center',*/}
        {/*        justifyContent: 'center',*/}
        {/*        display: 'flex',*/}
        {/*        color: 'white',*/}
        {/*        height: "100%",*/}
        {/*        width: "100%",*/}
        {/*    }}>*/}
        {/*        <h1>No Events Found!</h1>*/}
        {/*    </div>*/}
        {/*}*/}

    </div>
}

export const Bubble = ({
                           imageUrl, startDate, endDate, name, index
                       }) => {
    return <div style={{
        backgroundImage: `url("${bubbleImage}")`,
        backgroundSize: "601px 341px",
        height: "341px",
        width: "601px",
        marginRight: "100px",
        gridColumn: index % 4,
        gridRow: ((index - (index % 4)) / 4) + 1
    }}>
        <div style={{
            position: "relative",
            top: 50,
            left: 250,
            backgroundImage: `url("${imageUrl}")`
        }} />
        <div style={{
            position: "relative",
            top: 200,
            left: 20
        }}>{startDate.toLocaleString()}</div>
        <div style={{
            position: "relative",
            top: 200,
            left: 20
        }}>{endDate.toLocaleString()}</div>
        <div style={{
            position: "relative",
            top: 240,
            left: 20
        }}><h1>{name}</h1></div>

    </div>
}
