import './App.css';
import {useEffect, useState} from "react";
import {initializeApp} from 'firebase/app';
import {getRemoteConfig, getNumber, getString, getValue, fetchAndActivate} from "firebase/remote-config";

import {
    BrowserRouter,
    Routes,
    Route,
    Navigate
} from "react-router-dom";
import {IFrame} from "./Components/IFrame";


const firebaseConfig = {
    apiKey: "AIzaSyD0i6GZBknbCOQwBqEvmQdVlSBCfzTiNvk",
    authDomain: "frontdesk-screen.firebaseapp.com",
    databaseURL: "https://frontdesk-screen-default-rtdb.firebaseio.com",
    projectId: "frontdesk-screen",
    storageBucket: "frontdesk-screen.appspot.com",
    messagingSenderId: "597964985634",
    appId: "1:597964985634:web:7d77ebf5c4f2bc1963417b",
    measurementId: "G-VMTYYBL31P"
};

initializeApp(firebaseConfig);

const remoteConfig = getRemoteConfig();
remoteConfig.settings.minimumFetchIntervalMillis = 1000;

fetchAndActivate(remoteConfig);

remoteConfig.defaultConfig = {
    "refreshInterval": 10000,
    // "pageUrl": "https://frontdesk-screen.web.app/iframe"
    "pageUrlTest": "http://localhost:3000/iframe"
};

// donate pacifica                  -> /links/donate/pacifica
// donate embrace                   -> /links/donate/embrace
// donate salaam                    -> /links/donate/salaam
// donate anatolia                  -> /links/donate/anatolia
// subscribe to the newsletter en   -> /links/subscribe/platform
// subscribe to newsletter tr       -> /links/subscribe/platform_tr

const Home = () => {
    const [state, setState] = useState(0);
    const [src, setSrc] = useState(remoteConfig.defaultConfig.pageUrlTest);
    const [refreshRate, setRefreshRate] = useState(remoteConfig.defaultConfig.refreshInterval);

    useEffect(() => {
        const refreshInterval = getNumber(remoteConfig, "refreshInterval");
        const remoteSrc = getString(remoteConfig, "pageUrlTest");
        if (remoteSrc !== src) {
            setSrc(remoteSrc)
        }

        const interval = setInterval(() => {
            setState(state + 1);
            const remoteSrc = getString(remoteConfig, "pageUrlTest");
            console.log({refreshInterval, src})
            if (remoteSrc !== src) {
                setSrc(remoteSrc)
            }
        }, refreshInterval || 60000);
        return () => clearInterval(interval);
    }, [state, src, refreshRate]);

    return <div className="App">
        <iframe className="iframe" key={state} frameBorder={0} src={src}></iframe>
    </div>
}

const SubscribeNewsletter = () => {
    useEffect(() => {
        var sandboxSrc = "https://e26133ca7f874ee19c95a61b2fd2632a.svc.dynamics.com/t/formsandbox/6InwKB9sUoV9GYBJwkHQv_4ctJibdQmzUcgSJy6Sx0k/1e1bbdf7-2771-ec11-8943-000d3a8e7c45?ad=" + encodeURIComponent(document.location.toString());
        document.getElementById('4ec87592-19d0-4f3d-85ba-471b48c55abc').setAttribute('src', sandboxSrc);
    }, [])
    return <div style={{width: "100%", height: "100%"}}>
        <iframe height="100%" width="100%" frameBorder="0" id="4ec87592-19d0-4f3d-85ba-471b48c55abc"></iframe>
    </div>
}

const SubscribeNewsletterTR = () => {
    useEffect(() => {
        var sandboxSrc = "https://e26133ca7f874ee19c95a61b2fd2632a.svc.dynamics.com/t/formsandbox/I_4gEgBypI_Y-7I9VBzrzIi9vTDkChi6k-Ddqi3kh5c/0e20bd0d-3771-ec11-8943-000d3a8e7c45?ad=" + encodeURIComponent(document.location.toString());
        document.getElementById('e3186e6b-07f9-480b-bebb-dee7ddaea1e8').setAttribute('src', sandboxSrc);
    }, [])
    return <div style={{width: "100%", height: "100%"}}>
        <iframe height="100%" width="100%" frameBorder="0" id="e3186e6b-07f9-480b-bebb-dee7ddaea1e8"></iframe>
    </div>
}

const Redirect = ({address}) => {
    useEffect(() => {
        window.location.href = address
    }, [])
    return null
}


function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="iframe" element={<IFrame/>}/>
                <Route path={"links/subscribe/platform_eng"} element={<SubscribeNewsletter/>}/>
                <Route path={"links/subscribe/platform_tr"} element={<SubscribeNewsletterTR/>}/>
                <Route path={"links/donate/embrace"} element={<Redirect address="https://www.embracerelief.org/donation/support-us"/>}/>
                <Route path={"links/donate/anatolia"} element={<Redirect address="https://anatoliaculturalcenters.org/donation/"/>}/>
                <Route path={"links/donate/salaam"} element={<Redirect address="https://salaamic.org/donate"/>}/>
                <Route path={"links/donate/pacifica"} element={<Redirect address="https://pacificainstitute.org/donate/"/>}/>
                <Route path={"links/contact"} element={<Redirect address="mailto:support@socalcivicplatform.org?subject=I%20need%20help&body=How%20may%20we%20help%20you" />}/>
                {/*<Route path="invoices" element={<Invoices/>}/>*/}
            </Routes>
        </BrowserRouter>
    );
}

export default App;
